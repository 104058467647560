<template>

  <Valentine msg="Welcome to Your Vue.js App"/>
</template>

<script>
import Valentine from './components/Valentine.vue'

export default {
  name: 'App',
  components: {
    Valentine: Valentine
  }
}
</script>

<style>
#app {
  font-family: 'Playpen Sans', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;

}

/* h1 */
h1 {
  font-size: 2em;
  margin: 0.5em 0;
  color: rgb(255,192,203);
}

/* button */
button {
    padding:10px 20px;
    color:white;
    font-size:20px;
    font-weight:600;
    margin:10px;
    border:none;
    border-radius:5px;
    background-color: rgb(255,192,203);
}
</style>
