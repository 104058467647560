<template>
  <div style="text-align: center;">
    <div><h1 id="firstText">Do You Wanna Be My Valentine?</h1></div>
    
    
    <img style="max-width: 100%;" id="firstImage" src="@/assets/asking.gif" alt="">
    <!-- button on hover change position -->
    <p id="secondText"></p>
    <div style="margin: auto; text-align: center; display: flex; align-items: center; justify-content: center;">
      <div>
        <img style="max-width: 100%;"  id="secondImage" src="" alt="">
        <img style="max-width: 100%;"  id="thirdImage" src="" alt="">
      </div>
      
      <button id="yes" @click="yes" class="btn btn-primary" style="position: relative; left: 0px; top: 0px;">Yes</button>
      <button id="no" @mouseover="onHover" @mousedown="onHover" class="btn btn-primary" style="position: relative; left: 0px; top: 0px;">No</button>
    </div>
    
    
  </div>
</template>

<script>
export default {
  name: 'ValentinePage',
  props: {
    msg: String
  },
  methods: {
    onHover: function() {
      var nobtn = this.$el.querySelector('#no');
      // move button to random position across the screen
      var screenWidth = window.innerWidth;
      var screenHeight = window.innerHeight;
      nobtn.style.position = 'absolute';
      // positionn button randomly on the screen between 100 and screenWidth/height - 100
      nobtn.style.left = Math.floor(Math.random() * (screenWidth - 100)) + 'px';
      nobtn.style.top = Math.floor(Math.random() * (screenHeight - 100)) + 'px';

      console.log('hovered');
      console.log(nobtn.style.left);
    },
    yes: function() {
      // change picture to yes gif
      var firstImage = this.$el.querySelector('#firstImage');
      firstImage.src = require('@/assets/yes.gif');
      // change text to "I love you"
      var firstText = this.$el.querySelector('#firstText');
      firstText.innerHTML = "I Love You";
      var secondText = this.$el.querySelector('#secondText');
      secondText.innerHTML = "ich kann das alles nicht mehr.. double suicide?";
      // remove buttons
      var yesbtn = this.$el.querySelector('#yes');
      var nobtn = this.$el.querySelector('#no');
      yesbtn.style.display = 'none';
      nobtn.style.display = 'none';

      var secondImage = this.$el.querySelector('#secondImage')
      secondImage.src = require('@/assets/sus.gif')
      var thirdImage = this.$el.querySelector('#thirdImage')
      thirdImage.src = require('@/assets/mewing.gif')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
